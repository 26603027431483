import { debounce, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { HEADERS_FONT_FAMILY } from "../../gatsby-theme-material-ui-top-layout/theme"
import clsx from "clsx"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { getHeightPixelsInViewport } from "../../utils/browser-helpers"

const useStyles = makeStyles(theme => ({
  gatsbyLink: {
    color: "inherit",
    fontFamily: HEADERS_FONT_FAMILY,
    ...theme.typography.h6,
  },
  navbarButton: {
    "&.MuiGrid-item": {
      padding: theme.spacing(2.5, 4),
    },
  },
  navbarButtonActive: {
    backgroundColor: props => props.brandColor,
    color: props => props.textColor,
  },
}))

const NavbarLinks = props => {
  const { items, textColor, brandColor } = props
  const classes = useStyles({
    textColor,
    brandColor,
  })

  const [activeTab, setActiveTab] = React.useState("tworca")

  const fn = React.useMemo(
    () => () => {
      const result = items.reduce(
        (acc, curr) => {
          const [pixels] = acc
          const currPixels = getHeightPixelsInViewport(curr?.anchorId)

          if (currPixels > pixels) {
            return [currPixels, curr.anchorId]
          }

          return acc
        },
        [0, undefined]
      )

      result[1] && setActiveTab(result[1])
    },
    []
  )

  const debouncedFn = React.useMemo(() => debounce(fn, 50), [])

  React.useEffect(() => {
    window.addEventListener("scroll", debouncedFn)
    return () => window.removeEventListener("scroll", debouncedFn)
  }, [])

  return (
    <>
      {items.map(({ anchorId, label }) => (
        <Grid
          item
          key={label}
          className={clsx(classes.navbarButton, {
            [classes.navbarButtonActive]: activeTab === anchorId,
          })}
        >
          <AnchorLink
            to={`/projekty/${props.slug}#${anchorId}`}
            gatsbyLinkProps={{ className: classes.gatsbyLink }}
            variant="h6"
          >
            {label}
          </AnchorLink>
        </Grid>
      ))}
    </>
  )
}

export default NavbarLinks
