export function getHeightPixelsInViewport(elId) {
  const el = document.getElementById(elId)

  if (el) {
    const H = window.innerHeight,
      r = el.getBoundingClientRect(),
      elH = r.height,
      t = r.top,
      b = r.bottom
    return Math.max(0, t > 0 ? Math.min(elH, H - t) : b < H ? b : H)
  }

  return 0
}
