export const formatDate = date => {
  const result = new Intl.DateTimeFormat("pl", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date)

  return result
}
const miliseocndsInHour = 3600000
export const computeDaysAndHourstoDate = date => {
  const current = new Date()
  const diff = +date - +current
  const hoursAll = diff / miliseocndsInHour

  const days = Math.trunc(hoursAll / 24)
  const hoursReminder = Math.trunc(hoursAll % 24)

  return `${days}d ${hoursReminder}h`
}
