import { graphql } from "gatsby"
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  LinearProgress,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"
import React from "react"
import Tag from "../components/common/Tag"

import TagsTree from "../components/TagsTree"
import InstagramIcon from "../components/icons/InstagramIcon"
import LinkIcon from "../components/icons/LinkIcon"
import InstagramImages from "../components/sections/InstagramImages"
import Progress from "../components/Progress"
import {
  HEADERS_FONT_FAMILY,
  MOBILE_X_PADDING,
  YELLOW_BG,
} from "../gatsby-theme-material-ui-top-layout/theme"
import SectionContainer from "../components/layout/SectionContainer"
import BrandCardList from "../components/common/BrandCardList/BrandCardList"
import TitleAndParagraph from "../components/simple/TitleAndParagraph"
import Image from "../components/simple/Image"
import GridSection from "../components/sections/GridSection"
import DonateSection from "../components/sections/DonateSection"
import Layout from "../components/layout/Layout"
import { computeDaysAndHourstoDate, formatDate } from "../utils/date"
import ImageCard from "../components/ImageCard"
import NavbarLinks from "../components/common/NavbarLinks"
import { CurrencyContext, useCurrencies, useMobile } from "../utils/hooks"
import GatsbyImage from "../components/common/GatsbyImage"
import { hiddenOnMobile } from "../utils/constans"

const navbarLinks = [
  { label: "Twórca", anchorId: `tworca` },
  { label: "Materiały", anchorId: `materialy` },
  { label: "Filozofia", anchorId: `filozofia` },
  { label: "Wesprzyj", anchorId: `wesprzyj` },
]

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const mobile = useMobile()
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, fields } = markdownRemark
  const currencyValue = useCurrencies(frontmatter.currency)
  const datePercent =
    (((+frontmatter.dateEnd - +frontmatter.dateStart) * 100) / +new Date()) *
    100

  return (
    <CurrencyContext.Provider value={currencyValue}>
      <Layout
        navbarRightSlot={
          <Hidden implementation="js" {...hiddenOnMobile}>
            <NavbarLinks
              items={navbarLinks}
              slug={fields.slug}
              brandColor={frontmatter.brandColor}
              textColor={frontmatter.textColor}
            />
          </Hidden>
        }
      >
        <Grid container id="tworca">
          <Grid item xs={12} md={5} container>
            <GatsbyImage filename={frontmatter.image} alt={frontmatter.name} />
            {/* <Image src={frontmatter.image} alt={frontmatter.name} objectFit="contain" objectPosition="top" /> */}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            container
            direction="column"
            justify="space-between"
          >
            <TitleAndParagraph
              title={frontmatter.name}
              titleUppercase
              subtitle={frontmatter.location}
              paragraph={frontmatter.description}
              titleBoxProps={mobile ? {} : { left: "-30%" }}
              paragraphBoxProps={mobile ? {} : { mt: "-48px" }}
            />

            <Box
              display="flex"
              flexDirection="row"
              px={mobile ? MOBILE_X_PADDING : 8}
              mt={4}
              mb="auto"
            >
              <Box display="flex" alignItems="center">
                <InstagramIcon />
                <Box ml={2} />
                <Link to={frontmatter.instagramUrl} underline="none">
                  <Typography>{frontmatter.instagramLabel}</Typography>
                </Link>
              </Box>

              <Box display="flex" alignItems="center" ml={3}>
                <LinkIcon />
                <Box ml={2} />
                <Link to={frontmatter.websiteUrl} underline="none">
                  <Typography>{frontmatter.websiteLabel}</Typography>
                </Link>
              </Box>
            </Box>

            {Array.isArray(fields.instagramPhotos) &&
              fields.instagramPhotos.length && (
                <Box
                  display="flex"
                  flexDirection="column"
                  px={mobile ? MOBILE_X_PADDING : 8}
                  mt={mobile ? 8 : 2}
                  position="relative"
                >
                  <Typography variant="h4">Instagram</Typography>

                  <Box mt={4} />

                  <InstagramImages items={fields.instagramPhotos ?? []} />
                </Box>
              )}
          </Grid>

          <Hidden implementation="css" {...hiddenOnMobile}>
            <Grid item md={3}>
              <TagsTree variant="square" tags={frontmatter.tags} />
            </Grid>
          </Hidden>
        </Grid>

        {/* <Grid container>
        <Grid item md={5} />

        <Grid item md={4} container direction="column">
          
        </Grid>
        <Grid item md={3} />
      </Grid> */}

        <Container>
          <Box my={8}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <Progress
                  leftTopSlot={frontmatter.progress1.label}
                  rightTopSlot={frontmatter.progress1.secondLabelValue}
                  leftBottomSlot={`${frontmatter.progress1.valueCurrent} ${currencyValue.label}`}
                  rightBottomSlot={`${frontmatter.progress1.valueMax} ${currencyValue.label}`}
                  value={
                    (frontmatter.progress1.valueCurrent /
                      frontmatter.progress1.valueMax) *
                    100
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Progress
                  leftTopSlot={frontmatter.progress2.label}
                  rightTopSlot={computeDaysAndHourstoDate(frontmatter.dateEnd)}
                  leftBottomSlot={formatDate(frontmatter.dateStart)}
                  rightBottomSlot={formatDate(frontmatter.dateEnd)}
                  value={datePercent}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <SectionContainer
          bgcolor={YELLOW_BG}
          py={8}
          alignItems="center"
          flexDirection="column"
        >
          <Typography component="h2" variant={mobile ? "h2" : "h1"}>
            Kolekcje
          </Typography>

          <Box mt={8} />

          <Box width="100%">
            <Grid container spacing={2}>
              {frontmatter.collections.map((x, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <ImageCard
                    image={x.image}
                    title={x.name}
                    variant={"square"}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </SectionContainer>

        <GridSection items={frontmatter.photos} />

        <DonateSection
          companyUrl={fields.url}
          tags={frontmatter.donateOptions}
          donateProgress={frontmatter.donateProgress}
          progressSteps={frontmatter.donateProgressSteps}
          brandColor={frontmatter.brandColor}
          brandTextColor={frontmatter.textColor}
          ownerInfo={frontmatter.donateOwnerInfo}
        />
      </Layout>
    </CurrencyContext.Provider>
  )
}

export const pageQuery = graphql`
  query($slug: String) {
    markdownRemark(
      fields: { collection: { eq: "brands" }, slug: { eq: $slug } }
    ) {
      id
      frontmatter {
        textColor
        brandColor
        currency
        name
        description
        location
        image
        tags
        dateStart(formatString: "x")
        dateEnd(formatString: "x")
        collections {
          name
          image
        }
        photos {
          image0 {
            image
            position
            ratio
          }
          text1Title
          text1
          image1 {
            image
            position
            ratio
            ratioCustomWidth
            ratioCustomHeight
          }
          text2Title
          text2
          image2 {
            image
            position
            ratio
          }
          image3 {
            image
            position
            ratio
          }
          image4 {
            image
            position
            ratio
          }
          image5 {
            image
            position
            ratio
            ratioCustomWidth
            ratioCustomHeight
          }
          image6 {
            image
            position
            ratio
          }
          image7 {
            image
            position
            ratio
          }
        }
        progress1 {
          label
          secondLabel
          valueCurrent
          valueMax
        }
        progress2 {
          label
        }
        tags
        websiteUrl
        websiteLabel
        title
        instagramUrl
        instagramLabel
        donateProgress
        donateProgressSteps {
          title
          description
          value
        }
        donateOptions {
          sharesPromil
          contribution
          benefits
        }
        donateOwnerInfo {
          name
          description
          image
        }
      }
      fields {
        url
        slug
        instagramPhotos {
          thumbnails {
            src
            config_width
            config_height
          }
        }
      }
    }
  }
`
